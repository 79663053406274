html.dark {
  background-color: rgb(15, 23, 42);
}

.game-title {
  font-family: 'PT Serif', serif;
}

.monochrome-emoji {
  filter: grayscale(90%) brightness(100%) contrast(100%) saturate(10%);
}
